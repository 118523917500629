import React, { Component, Fragment } from 'react'
import axios from 'axios'
import Detail from './detail'
import { AddButton, Search } from '../../components'
import Card from './card'
import Modal from './modal'
import ModalEdit from './modalEdit'
import { toast } from 'react-toastify'
import CheckInput from '../../components/CheckInput'
import { produce } from 'immer'

class Users extends Component {
  state = {
    users: [],
    active: 0,
    search: '',
    modal: false,
    isEdit: false,
    isUsers: true,
    isAdmins: true,
    isExternals: true,
    isSupport: true,
  }

  async componentDidMount () {
    const { data } = await axios.get('/api/users')
    const users = data
    if (users) {
      this.setState({ users })
    }
  }

  setActive = active => () => this.setState({ active })

  setSearch = search => this.setState({ search, active: 0 })

  disableActive = () => this.setState({ active: -1 })

  addUser = () => {
    const TEXT = 'Please note: adding users in excess of your current license allotment will increase the amount of your monthly bill.'
    toast.warn(TEXT, {
      position: toast.POSITION.TOP_CENTER,
      autoClose: false,
    })
    this.setState({ modal: true })
  }

  onSubmit = async (newUser) => {
    const { users } = this.state
    this.setState({ modal: false })
    try {
      const { data } = await axios.post('/api/users', newUser)
      this.setState({ users: [...users, data] })
    } catch (e) {
      console.error(e)
    }
  }

  onSubmitEdit = async (user) => {
    const {
      users,
      active,
    } = this.state
    try {
      /* let { data } = */
      await axios.put('/api/users/' + users[active]._id, user)
      users[active] = { ...users[active], ...user }
      this.setState({ users: [...users] })
      this.onCancel()
    } catch (e) {
      console.error(e)
    }
  }

  onCancel = () => {
    this.setState({
      modal: false,
      isEdit: false,
    })
  }

  onEdit = () => {
    this.setState({ isEdit: true })
  }

  remove = async (index) => {
    const { users } = this.state
    /* let { data } = */
    await axios.delete('/api/users/' + users[index]._id)
    this.setState({ users: users.filter((user, _index) => _index !== index) })
    this.setState({ active: 0 })
  }

  onChangeFilter = (key) => {
    this.setState(produce(draft => {
      if (key in draft) {
        draft[key] = !draft[key]
        draft.active = 0
      }
    }))
  }

  filterUser = (user) => {
    console.log('user', user)
    const {
      isUsers,
      isAdmins,
      isExternals,
      isSupport,
    } = this.state

    if (!isSupport && user.mail.includes('@knackly.io')) {
      return false
    }

    if (!isExternals && user.isExternal) {
      return false
    }
    if (isUsers && user.isAdmin === false) {
      return true
    }
    if (!isUsers && isExternals && user.isExternal) {
      return true
    }
    if ((!isUsers || !isAdmins)
      && isSupport
      && user.mail.includes('@knackly.io')) {
      return true
    }
    if (isAdmins && user.isAdmin === true) {
      return true
    }

    return false
  }

  render () {
    const {
      users,
      active,
      search,
      isEdit,
      modal,
      isUsers,
      isAdmins,
      isExternals,
      isSupport,
    } = this.state
    if (!users.length) {
      return null
    }
    const Users = users.filter(this.filterUser).filter(user => !search
      || user.name.toUpperCase().includes(search.toUpperCase())
      || user.mail && user.mail.toUpperCase().includes(search.toUpperCase()))
    return (
      <Fragment>
        <div className="row">
          <div className="col-2 vh-75 overflow-auto">
            <label className="mb-2">USERS</label>
            <Search onSubmit={this.setSearch}/>
            <div className={'row mb-1'}>
              <div className={'col-12'}>
                <div className={'mx-2'}>
                  <div className={'row'}>
                    <div className="col">
                      <CheckInput id={'Users'}
                                  label={'Users'}
                                  className={'form-check'}
                                  onChange={() => this.onChangeFilter(
                                    'isUsers')}
                                  checked={isUsers}/>
                    </div>
                    <div className="col">
                      <CheckInput id={'Admins'}
                                  label={'Admins'}
                                  className={'form-check'}
                                  onChange={() => this.onChangeFilter(
                                    'isAdmins')}
                                  checked={isAdmins}/>
                    </div>
                    <div className="col">
                      <CheckInput id={'External'}
                                  label={'External'}
                                  className={'form-check'}
                                  onChange={() => this.onChangeFilter(
                                    'isExternals')}
                                  checked={isExternals}/>
                    </div>
                    <div className="col">
                      <CheckInput id={'Support'}
                                  label={'Support'}
                                  className={'form-check'}
                                  onChange={() => this.onChangeFilter(
                                    'isSupport')}
                                  checked={isSupport}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style={{
              maxHeight: '72vh',
              overflowY: 'auto',
            }}>
              {Users.
                map((user, index) =>
                  <Card
                    key={'user' + user._id}
                    name={user.name ? user.name : user.mail}
                    mail={user.mail}
                    type={user.type}
                    date={user.lastLogin}
                    user={user}
                    setActive={this.setActive}
                    index={index}
                    remove={this.remove}
                    active={active}/>)}
            </div>
            <div className={'my-2'}>
              <AddButton id={'addUsers'} label={'Add New User'}
                         onClick={this.addUser}/>
            </div>
          </div>
          {Users[active] &&
            <Detail item={Users[active]} disableActive={this.disableActive}
                    onEdit={this.onEdit}/>}
        </div>
        <Modal active={modal} onSubmit={this.onSubmit}
               listEmail={users.map(u => u.mail)} onCancel={this.onCancel}/>
        {Users[active] &&
          (<ModalEdit active={isEdit} user={Users[active]}
                      onSubmit={this.onSubmitEdit}
                      listEmail={users.map(u => u.mail)}
                      onCancel={this.onCancel}/>)}
      </Fragment>
    )
  }
}

export default Users
