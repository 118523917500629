import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Link } from 'react-router-dom'
import Markdown from '../../components/Markdown'
import { Button, Dropdown, Menu } from 'antd'
import { Icon } from '../../components'
import classNames from 'classnames'
import ModalConfirm from '../../components/ModalConfirm'
import styled from 'styled-components'
import { InferLabelFromAppName, IsIterable } from '../../utils'
import Attachments from './attachments'
import getFile from './getFile'

import { ReactComponent as Spinner } from '../../images/icons/spinner.svg'
import redirectNetDocuments from './redirectNetDocuments'
import redirectOneDrive from './redirectOneDrive'
import Histories from './histories'

const DetailsOfAdapter = styled.div`
  color: ${(props) => props.theme.colorTable.text || '#ffffff'} !important;
  background: ${(props) => props.theme.colorTable.plain};
`

const AppSection = styled.div`
  background: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'};
  color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'}
`
const LinkIcon = styled.a`
  color: ${(props) => props.theme.colorTable.backLightText ||
          '#ffffff'} !important
`

const recordDateFormat = 'lll'
const appDateFormat = 'lll'

const sortASC = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA < nameB) {
    return -1
  }
  if (nameA > nameB) {
    return 1
  }
  return 0
}
const sortDESC = (a, b) => {
  const nameA = a.name.toUpperCase()
  const nameB = b.name.toUpperCase()
  if (nameA > nameB) {
    return -1
  }
  if (nameA < nameB) {
    return 1
  }
  return 0
}

const sortLastRunByASC = (a, b) => (a.lastRun < b.lastRun) ? 1 : -1
const sortLastRunByDESC = (a, b) => (a.lastRun > b.lastRun) ? 1 : -1

let Detail = ({
                item,
                className,
                fileUrl,
                remove,
                adapter,
                typeLabel,
                allApps,
                checkInProgressOfItem,
                updateItem,
              }) => {
  const [sortName, setSortName] = useState(
    localStorage.getItem('sortName') || '')
  const [sortLastRun, setSortLastRun] = useState(
    localStorage.getItem('sortLastRun') || '')
  const [visibleHistories, setVisibleHistories] = useState(false)
  useEffect(() => {
    if (item.status === 'In Progress') {
      checkInProgressOfItem(item)
    }
  }, [item])

  const onVisibleHistories = () => {
    setVisibleHistories(true)
  }
  const onCancelHistories = () => {
    setVisibleHistories(false)
  }

  const changeSortName = () => {
    let nextSortName = ''
    if (sortName === '') {
      nextSortName = 'ASC'
    } else if (sortName === 'ASC') {
      nextSortName = 'DESC'
    }
    localStorage.setItem('sortName', nextSortName)
    setSortName(nextSortName)
  }
  const changeSortLastRun = () => {
    let nextSortLastRun = ''
    if (sortLastRun === '') {
      nextSortLastRun = 'ASC'
    } else if (sortLastRun === 'ASC') {
      nextSortLastRun = 'DESC'
    }
    localStorage.setItem('sortLastRun', nextSortLastRun)
    setSortLastRun(nextSortLastRun)
  }

  if (!item) {
    return null
  }

  const availableApps = [...allApps] // filtered below
  let itemApps = IsIterable(item.apps) ? [...item.apps].map(itemApp => {
    const thisItemApp = { ...itemApp }
    const oldLabel = (!thisItemApp.label || thisItemApp.label ===
      thisItemApp.name)
      ? InferLabelFromAppName(thisItemApp.name)
      : thisItemApp.label
    // find this app in the list of available apps
    const thisAppIdx = availableApps.findIndex(
      app => (thisItemApp.idApp && app._id &&
          (thisItemApp.idApp.toString() === app._id.toString())) ||
        (thisItemApp.name === app.name))
    if (thisAppIdx >= 0) {
      const thisApp = availableApps[thisAppIdx]
      thisItemApp.currentAppDef = thisApp
      thisItemApp.currentLabel = thisApp.label ||
        InferLabelFromAppName(thisApp.name)
      if (oldLabel.toLowerCase() !== thisItemApp.currentLabel.toLowerCase()) {
        thisItemApp.oldLabel = oldLabel
      }
      availableApps.splice(thisAppIdx, 1)
    } else {
      thisItemApp.currentLabel = oldLabel
    }
    return thisItemApp
  }) : []
  if (sortName === 'ASC') {
    itemApps = itemApps.sort(sortASC)
  }
  if (sortName === 'DESC') {
    itemApps = itemApps.sort(sortDESC)
  }
  if (sortLastRun === 'ASC') {
    itemApps = itemApps.sort(sortLastRunByASC)
  }
  if (sortLastRun === 'DESC') {
    itemApps = itemApps.sort(sortLastRunByDESC)
  }
  return (
    <div className="col-8">
      <div className="mb-2">
        <div className="col-4">DETAILS</div>
      </div>
      <DetailsOfAdapter className="col-12 border overflow-auto"
                        style={{ maxHeight: 'calc(100vh - 9.5rem)' }}>
        <div className="d-flex justify-content-between mt-3">
          <div>
            {item.name &&
              <Markdown className={className}>{item.name.startsWith('#') ? item.name : ('# ' + item.name)}</Markdown>}
          </div>
          <div>
            <Dropdown overlay={
              <Menu>
                <Menu.Item>
                  <LinkIcon onClick={getFile(fileUrl + '/export',
                    (item.name || 'record') + '.json')} download>
                    <Icon type={'icon-export'}
                          style={{
                            fontSize: '18px',
                            verticalAlign: '0.1em',
                          }}
                    />
                    <span> Export</span>
                  </LinkIcon>
                </Menu.Item>
                <Menu.Item onClick={onVisibleHistories}>
                  <Icon type={'icon-history'}
                            style={{
                              fontSize: '18px',
                              verticalAlign: '0.1em',
                              marginRight: 5,
                            }}
                  />
                  <span>History</span>
                </Menu.Item>
                {item.extUserID && (
                  <Menu.Item
                    onClick={() => updateItem(item._id, { extUserID: null })}>
                   <span><Icon type="icon-ban" style={{
                     fontSize: '17px',
                     verticalAlign: '0.1em',
                   }}/><span> Close External Access</span></span>
                  </Menu.Item>)}
                <Menu.Item>
                  <ModalConfirm
                    title={`Are you sure you want to delete item ${item.name}?`}
                    submit={() => {
                      remove(item._id)
                    }}>
                    <span><Icon type="icon-trash" style={{
                      fontSize: '18px',
                      verticalAlign: '0.1em',
                    }}/><span> Delete item</span></span>
                  </ModalConfirm>
                </Menu.Item>
              </Menu>}
            >
              <Button title="Menu" shape="circle" className="circle-ant-btn">
                <Icon type="icon-bars" className="circle-ant-btn-icon"/>
              </Button>
            </Dropdown>
          </div>
        </div>
        <div className="row">
          <div className="col-8 my-2">
            {item.detail && <Markdown>{item.detail}</Markdown>}
          </div>
          <div className="col-4 my-2">
            <div className="row">
              <div className="col-5 my-1">Status:</div>
              <div className="col-7 my-1"><Icon
                type={Icon.fromStatus(item.status)}
                style={{
                  fontSize: '18px',
                  verticalAlign: '0.05rem',
                }}
                className="mx-2"/> {item.status}</div>
              <div className="col-5 my-1">Last modified:</div>
              <div className="col-7 my-1">{moment(item.date).format(recordDateFormat)}</div>
              <div className="col-5 my-1">Last modified by:</div>
              <div className="col-7 my-1">{item.lastRunBy}</div>
              <div className="col-5 my-1">External access:</div>
              <div className="col-7 my-1">{item.extUserID
                ? 'Open'
                : 'Closed'}</div>
            </div>
          </div>
        </div>
        <div className="col-12 my-2">
          <div className="row my-2">
            <div className="col-5">
              <span
                className={classNames(
                  'd-flex align-items-center cursorPointer blockSort',
                  { 'active': sortName })}
                onClick={changeSortName}
              >APP <Icon
                className={'mx-1 menuDeleteLayout'}
                type={sortName === '' || sortName === 'ASC'
                  ? 'icon-arrow-up'
                  : 'icon-arrow-down'}/></span>
            </div>
            <div className="col-3">
               <span
                 className={classNames(
                   'd-flex align-items-center cursorPointer blockSort',
                   { 'active': sortLastRun })}
                 onClick={changeSortLastRun}
               >LAST MODIFIED <Icon
                 className={'mx-1 menuDeleteLayout'}
                 type={sortLastRun === '' || sortLastRun === 'ASC'
                   ? 'icon-arrow-up'
                   : 'icon-arrow-down'}/></span>
            </div>
            <div className="col-2">
              STATUS
            </div>
            <div className="col-2 text-center">
              ACTION
            </div>
          </div>
          {itemApps.map(itemApp => (
            <AppSection className="row my-2 py-2" key={itemApp.name}>
              <div className="col-5 py-1"><span
                className="font-weight-bold">{itemApp.currentLabel}</span>
              </div>
              <div className="col-3 py-1">{moment(itemApp.lastRun).format(appDateFormat)}</div>
              <div className="col-2 py-1">
                {itemApp.status}
              </div>
              <div className="col-2 text-center">
                {itemApp.currentAppDef ? (
                  <Link className={'btn btn-secondary btn-block p-1 btn-theme'}
                        to={{
                          pathname: `/${window.tenancy}/${adapter}/${item._id}/${itemApp.currentAppDef.name}`,
                        }}>Review or Update</Link>
                ) : (
                  <i>(app not found)</i>
                )}
              </div>

              {itemApp.status === 'In Progress' &&
                <div className={'col-5 py-1 text-center'}><Spinner
                  className={'rot'} style={{ width: 50 }}/>
                </div>}

              {itemApp.files.length > 0 && <>
                <div className={'col-10 py-1'}>
                  {itemApp.files.map((file, index) => {
                    const linkUrl = fileUrl + '/' + itemApp._id.toString() +
                      '/' + index
                    return (
                      <div key={linkUrl}
                           id={itemApp.name.toLowerCase() + '-file-' + index}
                           onClick={(e) => {
                             if (file.storage) {
                               if (file.storage === 'NetDocuments') {
                                 return redirectNetDocuments(file.path,
                                   e.shiftKey)
                               }
                               if (file.storage === 'OneDrive') {
                                 return redirectOneDrive(file.path, e.shiftKey)
                               }
                             }
                             return getFile(linkUrl, file.base)()
                           }}
                           className={'d-flex'}>
                        <LinkIcon
                          className={'mb-1 text-truncate w-100'}
                        >
                          <Icon type={Icon.fromFileName(file.base)} style={{
                            fontSize: '18px',
                            verticalAlign: '0.1em',
                          }}
                                className={'mr-1 mb-1'}/>
                          {file.base} {file.storage === 'NetDocuments' &&
                          (<em>(NetDocuments)</em>)}
                          {file.storage === 'OneDrive' &&
                            (<em>(OneDrive)</em>)}
                        </LinkIcon>
                      </div>
                    )
                  })}
                </div>
                <div className="col-2 py-1">
                  {itemApp.files.length > 1 &&
                    (<button
                      className={'btn btn-secondary btn-block p-1 btn-theme'}
                      disabled={itemApp.files.find(
                        file => file.storage === 'NetDocuments' ||
                          file.storage === 'OneDrive')}
                      onClick={getFile(
                        fileUrl + '/' + itemApp._id.toString() + '/all',
                        item.name + ' - ' + itemApp.name + '.zip')}>
                      <Icon type="icon-download1" style={{
                        fontSize: '1rem',
                        verticalAlign: '0.15em',
                      }
                      }/> Download All
                    </button>)}
                  {itemApp.files.length === 1 &&
                    (<button
                      className={'btn btn-secondary btn-block p-1 btn-theme'}
                      disabled={itemApp.files.find(
                        file => file.storage === 'NetDocuments')}
                      onClick={(e) => {
                        const file = itemApp.files[0]
                        if (file.storage) {
                          if (file.storage === 'NetDocuments') {
                            return redirectNetDocuments(file.path, e.shiftKey)
                          }
                          if (file.storage === 'OneDrive') {
                            return redirectOneDrive(file.path, e.shiftKey)
                          }
                        }
                        return getFile(
                          fileUrl + '/' + itemApp._id.toString() + '/0',
                          file.base)()
                      }
                      }>
                      <Icon type="icon-download1" style={{
                        fontSize: '1rem',
                        verticalAlign: '0.15em',
                      }}/> Download
                    </button>)}
                </div>
              </>}
            </AppSection>
          ))}
          {item.attachments && item.attachments.length
            ? <Attachments attachments={item.attachments} link={fileUrl}
                           itemName={item.name}/>
            : null}
        </div>
        {availableApps.length > 0 && (
          <div className={'py-3 d-flex flex-column'}>
            <label htmlFor={'dropdownMenuButton'}>Add to this record...</label>
            <Dropdown overlay={(
              <Menu>
                {availableApps.map(app => <Menu.Item
                  key={app.name}><Link
                  to={{
                    pathname: `/${window.tenancy}/${adapter}/${item._id}/${app.name}`,
                  }} key={app.name}>{app.label ||
                  InferLabelFromAppName(app.name)}</Link></Menu.Item>)}
              </Menu>)}
            >
              <button className="btn btn-secondary dart-btn dropdown-toggle"
                      type="button" id="dropdownMenuButton">
                SELECT APP&nbsp;
              </button>
            </Dropdown>
          </div>
        )}
      </DetailsOfAdapter>
      <Histories visible={visibleHistories}
                 recordId={item._id}
                 fileName={item.name || 'record'}
                 checkInProgressOfItem={checkInProgressOfItem}
                 onCancel={onCancelHistories}
      />
    </div>
  )
}
Detail = styled(Detail)`
  color: ${(props) => props.theme.colorTable.text || '#ffffff'}
`
export default Detail
