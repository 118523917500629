import { CHANGE_SETTINGS } from '../../action/settings'
import { setLocale, validateLocale } from '../../utils/locales'
import { URI } from '../../config'

const initState = { label: '' }

function settings (state = initState, action) {
  switch (action.type) {
    case CHANGE_SETTINGS: {
      console.log('CHANGE_SETTINGS', action.settings)
      const newSettings = { ...action.settings }
      newSettings.locale = validateLocale(newSettings.locale)
      setLocale(newSettings.locale)
      let link = document.querySelector('link[rel~=\'icon\']')
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }
      link.href = newSettings.favicon
        ? `${URI}${window.location.pathname.replace(/(\/[^\/]*\/).*/, "$1")}${newSettings.favicon}`
        : '/favicon.ico'
      return JSON.parse(JSON.stringify(newSettings))
    }
    default :
      return state
  }
}

export default settings
