import React, { Fragment } from 'react'
import { Select } from '../../../../components'
import EditMemberPropertyComponent from '../../EditMemberPropertyComponent'

const TRUEFALSE = 'true/false'

function TrueFalse () {
  return (
    <Fragment>
      <div className={'col-12'}>
        <hr/>
        TRUE/FALSE INTAKE
      </div>
      <div className={'col-6'}>
        <EditMemberPropertyComponent path={'style'} defaultValue={'checkbox'}>
          {({
            value,
            onChange,
          }) => (
            <Select label={'Style'.toUpperCase()} id={'numberStyle'}
                    value={value || 'checkbox'}
                    values={
                      value === 'hidden'
                        ? ['checkbox', 'switch', 'radiobuttons', 'hidden']
                        : ['checkbox', 'switch', 'radiobuttons']
                    }
                    onChange={onChange}/>)}
        </EditMemberPropertyComponent>
      </div>
    </Fragment>
  )
}

export { TRUEFALSE, TrueFalse }
