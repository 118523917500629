import React from 'react'
import { Modal } from 'antd'
import { useRouteMatch } from 'react-router-dom'


const About = ({ visible, onInvisible }) => {
  const matchApp = useRouteMatch([
    '/:tenancy/:adapter/:application',
    '/:tenancy/:adapter/:record_id/:application'])
  if (matchApp) {
    if (!window.appInfo) {
      return null
    }
    return (<Modal title={'About ' + window.appInfo.label || ''}
                   visible={visible}
                   onCancel={onInvisible}
                   closable={false}
                   width={410}
                   footer={[
                     <button key="submit"
                             className={'btn btn-secondary dart-btn'}
                             style={{ width: 125 }} onClick={onInvisible}>
                       Ok
                     </button>,
                   ]}
    >
      <p>Name: {window.appInfo.name || ''}</p>
      <p>Key: {window.appInfo.key || ''}</p>
      <p>Environment: {window.appInfo.env || ''}</p>
      <p>Client version : {new Date(window.clientVersion).toISOString()}</p>
    </Modal>)
  }

  return (<Modal title={'About Knackly'}
                 visible={visible}
                 onCancel={onInvisible}
                 closable={false}
                 width={410}
                 footer={[
                   <button key="submit" className={'btn btn-secondary dart-btn'}
                           style={{ width: 125 }} onClick={onInvisible}>
                     Ok
                   </button>,
                 ]}
  >
    <p>Client version : {new Date(window.clientVersion).toISOString()}</p>
  </Modal>)
}

export default About
