import { CHANGE_COLORS } from '../../action/colors'

const initState = JSON.parse(localStorage.getItem('colors')) || ['#F26B2F', '#CBCBCB', '#FFFFFF']

function colors (state = initState, action) {
  switch (action.type) {
    case CHANGE_COLORS: {
      localStorage.setItem('colors', JSON.stringify(action.colors))
      return [...action.colors]
    }
    default :
      return state
  }
}

export default colors
