import React, { Fragment, useState } from 'react'
import classNames from 'classnames'
import './index.css'
import validateEmail from '../../utils/validateEmail'
import validatePassword from '../../utils/validatePassword'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as Google } from '../../images/icons/google.svg'
import { ReactComponent as Facebook } from '../../images/icons/facebook.svg'
import { ReactComponent as Microsoft } from '../../images/icons/microsoft.svg'
import { ReactComponent as Envelope } from '../../images/icons/envelope.svg'
import queryString from 'query-string'
import LogonForExternal from '../loginForExternal'
import { URI } from '../../config'
import getWorkspaceURL from '../../config/workspaceURL'

const LabelSignIn = styled.h1`
  color: ${props => props.theme.colorTable.text}
`
const CustomLink = styled(Link)`
  color: ${props => props.theme.colorTable.text}
`

const BlockLogo = styled.div`
  background-color: ${props => props.theme.colorTable.menu};
  color: ${props => props.theme.colorTable.menuText}
`
const SocialLogin = styled.button`
  background: white !important;
  border-color: ${props => props.theme.colorTable.widget} !important;
  color: ${props => props.theme.colorTable.text} !important;
  font-weight: 500 !important;
  padding: 12px !important;
`

const Signin = ({
  login,
  settings,
  registration,
  location,
}) => {

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [invalidEmail, setInvalidEmail] = useState(false)
  const [invalidPassword, setInvalidPassword] = useState(false)
  const getsParameters = queryString.parse(window.location.search)
  const isExternal = getsParameters.external === null
  const [openExternal, setExternal] = useState(false)
  const createLinkForLogin = type => {
    let extraParms = ''
    if (getsParameters.external === null) {
      let [, , collection, app] = window.location.pathname.split('/')
      extraParms = `?collection=${collection}&app=${app}&external=1`
    }
    return process.env.NODE_ENV === 'development'
      ? `http://localhost:5050/${window.tenancy}/api/auth/${type}${extraParms}`
      : `${getWorkspaceURL()}/api/auth/${type}${extraParms}`
  }
  const createLinkForLoginInternal = type => {
    return process.env.NODE_ENV === 'development'
      ? `http://localhost:5050/${window.tenancy}/api/auth/${type}`
      : `${getWorkspaceURL()}/api/auth/${type}`
  }
  const submit = () => {
    let instanceEmail = email.toLowerCase()
    if (validateEmail(email) && password.length > 5) {
      setInvalidEmail(false)
      setInvalidPassword(false)
      login({
        email: instanceEmail,
        password,
      })
    } else {
      if (!validateEmail(email) && !validatePassword(password)) {
        toast.error(
          'The email or password does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
          })
        setInvalidEmail(true)
        setInvalidPassword(true)
      } else {
        if (!validateEmail(email)) {
          toast.error(
            'The email does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            })
          setInvalidEmail(true)
        } else {
          setInvalidEmail(false)
        }
        if (!validatePassword(password)) {
          toast.error(
            'The password does not match the user account, or the account does not exist. Please verify both the user name and password and try again.',
            {
              position: toast.POSITION.TOP_CENTER,
              autoClose: false,
            })
          setInvalidPassword(true)
        } else {
          validatePassword(false)
        }
      }
    }
  }
  if ((openExternal || window.knacklyAddons.no3p_auth_ext) && isExternal) {
    return <LogonForExternal login={login} registration={registration}
                             settings={settings}
                             location={location}
                             goBack={() => {
                               console.log('goBack')
                               setExternal(false)
                             }}/>
  }
  const nameTenancy = settings.label
    ? settings.label
    : settings.name
  return (
    <Fragment>
      {settings.logo && <BlockLogo className={'w-100 text-center py-3'}>
        <img src={`${URI}/${window.tenancy}/` + settings.logo} style={{
          maxWidth: '50%',
          maxHeight: 100,
        }} alt={settings.label ? settings.label : settings.name}/>
      </BlockLogo>}
      <div className={settings.logo ? 'pt-5' : ''}
           style={!settings.logo ? { paddingTop: '10%' } : {}}>
        <div className={'mx-auto signin text-center d-flex flex-column p-5 '}>
          <LabelSignIn
            className={'mb-5 font-weight-bold'}>{settings.label
            ? settings.label
            : settings.name}</LabelSignIn>
          <p className={'mb-5'}>{settings.isRegistration && isExternal
            ? 'Please sign in to access '
            : 'Sign in to your'} {settings.isRegistration && isExternal
            ? settings.portalName
              ? settings.portalName : nameTenancy
            : nameTenancy} {settings.isRegistration && isExternal
            ? ''
            : 'account'}</p>
          {(settings.isRegistration && isExternal) && (<Fragment>
            <SocialLogin className={'mb-2 socialLogin rounded text-left'}
                         onClick={() => {
                           window.location.replace(createLinkForLogin('google'))
                         }}><Google className={'iconSocial'}/>Sign in with
              Google</SocialLogin>
            <SocialLogin className={'my-2 socialLogin rounded text-left'}
                         onClick={() => {
                           window.location.replace(
                             createLinkForLogin('facebook'))
                         }}
            ><Facebook className={'iconSocial'}/>Sign in with
              Facebook</SocialLogin>
            <SocialLogin className={'my-2 socialLogin rounded text-left'}
                         onClick={() => {
                           window.location.replace(
                             createLinkForLogin('microsoft'))
                         }}
            ><Microsoft className={'iconSocial'}/>Sign in with
              Microsoft</SocialLogin>
            <SocialLogin
              className={'my-2 socialLogin rounded text-left'}
              onClick={() => {
                setExternal(true)
              }}>
              <Envelope className={'iconSocial'}/>Sign in with
              email</SocialLogin>
          </Fragment>)}
          {!isExternal && !window.knacklyAddons.ms_auth_int &&
            (<form onSubmit={event => {
              event.preventDefault()
              submit()
            }}>
              <input
                className={classNames('form-control mt-1 mb-3 rounded-0',
                  { 'is-invalid': invalidEmail })}
                value={email}
                onChange={e => setEmail(e.target.value)}
                id={'email'} type="email"
                placeholder="Email"/>
              <input className={classNames('form-control my-3 rounded-0',
                { 'is-invalid': invalidPassword })}
                     value={password}
                     onChange={e => setPassword(e.target.value)} id={'password'}
                     type="password"
                     placeholder="Password"/>
              <CustomLink className={'my-2'}
                          to={`/${window.tenancy}/resetPassword`}>Forgot your
                password?</CustomLink>
              <button className={'dart-btn m-0 h-100 my-2 rounded'}>Sign In
              </button>
              <div className={'separate'}>
                <p>OR</p>
              </div>
            </form>)}
          {(!isExternal) &&
            (<SocialLogin className={'my-2 socialLogin rounded text-left'}
                          onClick={(event) => {
                            window.location.replace(
                              createLinkForLoginInternal('microsoft'))
                            event.stopPropagation()
                          }}
            >
              <Microsoft className={'iconSocial'}/>Sign in with
              Microsoft</SocialLogin>)}
        </div>
      </div>
    </Fragment>
  )
}

export default Signin
