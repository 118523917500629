import React, { Fragment, useState, useMemo } from 'react'
import { Modal } from 'antd'
import { AddButton, Input, Select } from '../../../components'
import { useHistory } from 'react-router-dom'
import {
  atom,
  selector,
  selectorFamily,
  useRecoilCallback, useRecoilState,
  useRecoilValue, useSetRecoilState,
} from 'recoil'
import { CurrentTypeSelector, ElementsChangesAtom } from '../state'
import produce from 'immer'
import { ObjectID } from 'bson'
import _ from 'lodash'
import { connect } from 'react-redux'

const DEFAULT_NEW_APP_STATE = {
  name: '',
  nameTouched: false,
  showErrors: false,
  enableApp: false,
  template: '',
}

const NewAppState = atom({
  key: 'NewAppState',
  default: DEFAULT_NEW_APP_STATE,
})

const EditorNewApp = selectorFamily({
  key: 'EditorNewApp',
  get: path => ({ get }) => {
    return _.get(get(NewAppState), path)
  },
  set: path => ({
    set,
    get,
  }, newValue) => {
    set(NewAppState, produce(draft => {
      _.set(draft, path, newValue)
      if (path === 'name') {
        _.set(draft, 'showErrors', true)
      }
    }))
  },
})

const NewAppErrors = selector({
  key: 'NewAppErrors',
  get: ({ get }) => {
    const {
      name,
    } = get(NewAppState)

    if (!name) {
      return ({ 'name': 'Please provide a name.' })
    }

    return {}
  },
})

const ErrorAlert = () => {
  const errors = useRecoilValue(NewAppErrors)
  const showErrors = useRecoilValue(EditorNewApp('showErrors'))
  if (errors.name && showErrors) {
    return (<p className={'text-danger'}>
      {errors.name}
    </p>)
  }
  return (<p>&#8203;</p>)
}

const NameProperty = ({ visible }) => {
  const [name, setName] = useRecoilState(EditorNewApp('name'))
  const setNameTouched = useSetRecoilState(EditorNewApp('nameTouched'))
  return (<Input type={'text'} value={name}
                 onChange={setName}
                 label={'APP NAME'}
                 id={'modalNewTypeName'}
                 onBlur={() => {if (visible) setNameTouched(true)}}/>)
}

const TemplateProperty = () => {
  const [template, setTemplate] = useRecoilState(EditorNewApp('template'))
  const { templates } = useRecoilValue(CurrentTypeSelector)
  return (<Select onChange={setTemplate}
                  id={'tempAddNewApp'}
                  value={template}
                  values={['', ...templates.map(t => t.name)]}
                  label={'Initial Template'}/>)
}

const EnableAppProperty = () => {
  const [enableApp, setEnableApp] = useRecoilState(EditorNewApp('enableApp'))
  return (<div className="form-check">
    <input className="form-check-input" type="checkbox"
           id="skipDisabledAppOptions"
           onChange={() => setEnableApp(
             !enableApp)}
           checked={!!enableApp}/>
    <label className="form-check-label"
           htmlFor="skipDisabledAppOptions">
      Enable App for All Catalogs
    </label>
  </div>)
}

const NewApp = ({ collections }) => {
  const [visible, setVisible] = useState(false)
  const currentType = useRecoilValue(CurrentTypeSelector)
  const history = useHistory()

  const onOpen = () => setVisible(true)

  const isUpdateCollections = useMemo(() => {
    if (!currentType) {
      return false
    }
    return _.sumBy(collections, _c => _c.type._id === currentType._id) > 1
  }, [collections, currentType])

  const onSubmit = useRecoilCallback(({
    snapshot,
    set,
  }) => async () => {
    const app = await snapshot.getPromise(NewAppState)
    const errors = await snapshot.getPromise(NewAppErrors)
    const type = await snapshot.getPromise(CurrentTypeSelector)
    if (!errors.name) {
      set(ElementsChangesAtom, produce(draft => {
        draft.push({
          _id: new ObjectID().toString(),
          action: 'add',
          kind: 'app',
          parent: type._id,
          parentKind: 'model',
          name: app.name,
          screens: [],
          templates: app.template ? [app.template] : [],
          generateDocs: true,
          enableApp: app.enableApp,
          isUpdateCollections: isUpdateCollections,
        })
      }))

      history.push(
        `/${window.tenancy}/types/${type.name}/apps/${app.name}`)

      onCancel()
    } else {
      set(EditorNewApp('showErrors'), true)
    }
  }, [isUpdateCollections])

  const onCancel = useRecoilCallback(({ set }) => () => {
    setVisible(false)
    set(NewAppState, DEFAULT_NEW_APP_STATE)
  }, [])

  const MemoAddButton = useMemo(() => (
      <AddButton id={'addApp'} onClick={onOpen} label={'Add a new app'}/>),
    [])

  return (
    <Fragment>
      <Modal
        title="New App"
        visible={visible}
        onCancel={onSubmit}
        maskClosable={false}
        closable={false}
        width={510}
        footer={[
          <button key="submit" className={'btn btn-secondary dart-btn'}
                  style={{ width: 125 }} onClick={onSubmit}>
            Ok
          </button>,
          <button key="back"
                  className={'btn btn-outline-secondary dart-btn-outline'}
                  style={{ width: 125 }}
                  onClick={onCancel}>Cancel</button>,
        ]}
      >
        <form onSubmit={e => {
          e.preventDefault()
          onSubmit()
        }}>
          <div className={'row'}>
            <div className={'col-6'}>
              <NameProperty visible={visible}/>
              <ErrorAlert/>
            </div>
            <div className="col-6">
              <TemplateProperty/>
            </div>
            <div className="col-12">
              {isUpdateCollections && (<EnableAppProperty/>)}
            </div>
          </div>

        </form>
      </Modal>
      {MemoAddButton}
    </Fragment>
  )
}

const mapStateToProps = state => {
  const {
    collections,
  } = state
  return {
    collections,
  }
}

const mapDispatchToProps = () => ({})
export default connect(mapStateToProps, mapDispatchToProps)(NewApp)
