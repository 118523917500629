import React, { Component } from 'react'
import { FixedSizeList as List } from 'react-window'
import axios from 'axios'
import { connect } from 'react-redux'
import { Link, Redirect } from 'react-router-dom'
import { InferLabelFromAppName, InferLabelFromName } from '../../utils'
import styled from 'styled-components'
import Detail from './detail'
import Search from '../../components/Search'
import { Dropdown, Menu } from 'antd'
import RowComponent from './row'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { Icon } from '../../components'
import ModalConfirm from '../../components/ModalConfirm'
import ImportItems from './import'
import _ from 'lodash'
import Filters from './filters'
import getWorkspaceURL from '../../config/workspaceURL'
import ClearDuplicates from './clearDuplicates'

const AdapterMain = styled.div`
  background: ${(props) => props.theme.colorTable.back || '#9B9B9B'}
  color: ${(props) => props.theme.colorTable.backText || '#ffffff'}
  border-color: ${(props) => props.theme.colorTable.backBorder || '#ffffff'}
`

class Adapter extends Component {

  getUrl = () => getWorkspaceURL()

  state = {
    items: [],
    selectedItems: { 0: null },
    hasNextPage: true,
    isNextPageLoading: false,
    url: this.getUrl(),
    active: 0,
    visibleImport: false,
    clearDuplicates: false,
  }

  onClose = () => {
    this.setState({ visibleImport: false })
  }

  onOpenVisibleImport = () => {
    this.setState({ visibleImport: true })
  }

  onOpenClearDuplicates = () => {
    this.setState({ clearDuplicates: true })
  }

  onCloseClearDuplicates = () => {
    this.setState({ clearDuplicates: false })
  }

  getCollection = () => {
    let {
      match: { params: { adapter } },
      collections,
    } = this.props
    let collection = collections.find(collection => collection.name === adapter)
    if (collection) {
      return collection
    } else {
      return { apps: [] }
    }
  }

  getGlobalType = (typeName) => {
    let { collections } = this.props
    let collection = collections.find(c => c.type && c.type.name === typeName)
    return collection && collection.type
  }

  async getItems (props) {
    let { match: { params: { adapter } } } = props
    const SearchParams = new URLSearchParams(window.location.search)
    const filters = SearchParams.get('f')
    const search = SearchParams.get('s')
    let params = {}
    if (search) {
      _.set(params, 's', search)
    }
    if (filters) {
      _.set(params, 'f', filters)
    }
    let { data: { items } } = await axios.get(
      `/api/collections/${adapter}/items`, { params })
    return items
  }

  loadFirstItems = async () => {
    let items = await this.getItems(this.props)
    this.setState({
      items,
      hasNextPage: items.length === 20,
    })
  }

  async getNextItems () {
    let { match: { params: { adapter } } } = this.props
    let {
      items,
    } = this.state

    this.setState({ isNextPageLoading: true })

    const SearchParams = new URLSearchParams(window.location.search)
    const filters = SearchParams.get('f')
    const search = SearchParams.get('s')

    let params = {
      skip: items.length,
    }

    if (search) {
      _.set(params, 's', search)
    }
    if (filters) {
      _.set(params, 'f', filters)
    }

    let { data } = await axios.get(`/api/collections/${adapter}/items`,
      { params })

    this.setState({
      items: [...items, ...data.items],
      hasNextPage: data.items.length === 20,
      isNextPageLoading: false,
    })
  }

  async getItem (id) {
    let { match: { params: { adapter } } } = this.props
    let { data: { item } } = await axios.get(
      `/api/collections/${adapter}/items/${id}`)
    return item
  }

  checkInProgressOfItem = async (item) => {
    clearTimeout(this.timerID)
    const IN_PROGRESS = 'In Progress'
    const nextItem = await this.getItem(item._id)
    if (nextItem) {
      if (nextItem.status === IN_PROGRESS) {
        this.timerID = setTimeout(() => {
          this.checkInProgressOfItem(item)
        }, 1000)
      } else {
        let { items } = this.state
        const nextItems = items.map(item => {
          if (item._id === nextItem._id) {
            return nextItem
          } else {
            return item
          }
        })
        this.setState({ items: [...nextItems] })
      }
    } else {
      console.log(`item ${item._id} not found when checking progress`)
    }
  }

  async componentDidMount () {
    let items = await this.getItems(this.props)
    this.setState({
      items,
      hasNextPage: items.length === 20,
    })
    const collection = this.getCollection()
    if (collection.myRecords) {
      const searchParams = new URLSearchParams(window.location.search)
      if (!searchParams.get('f')) {
        searchParams.set('f', JSON.stringify({ myrecords: {} }))
        this.props.history.push(
          {
            pathname: `/${window.tenancy}/${collection.name}`,
            search: searchParams.toString(),
          })
      }
    }
  }

  componentWillUnmount () {
    clearInterval(this.timerID)
  }

  async shouldComponentUpdate (nextProps, nextState) {

    if (this.props.match.params.adapter !== nextProps.match.params.adapter) {
      let items = await this.getItems(nextProps)
      this.setState({ items })
    }
    return this.props.match.params.adapter !== nextProps.match.params.adapter
  }

  removeItem = async id => {
    let { match: { params: { adapter } } } = this.props
    let {
      active,
      items,
    } = this.state
    try {
      let { status } = await axios.delete(
        `/api/collections/${adapter}/items/` + id)
      if (status === 200) {
        items.splice(active, 1)
        this.setState({
          items,
          active: 0,
        })
      }
    } catch (e) {
      console.error(e)
    }
  }

  setActive = (type, active) => {
    if (type === 'click') {
      this.setState({
        active,
        selectedItems: { [active]: null },
      })
      return
    }
    if (type === 'select') {
      this.setState((preState) => {

        if (preState.selectedItems[active] === null) {
          delete preState.selectedItems[active]
        } else {
          preState.selectedItems[active] = null
        }
        if (!Object.entries(preState.selectedItems).length) {
          preState.selectedItems[active] = null
        }
        return preState
      })
    }
  }

  disableActive = () => this.setState({ active: null })

  onSearch = async search => {
    const SearchParams = new URLSearchParams(window.location.search)
    const collection = this.getCollection()
    if (!_.isNil(search)) {
      if (search) {
        SearchParams.set('s', search)
      } else {
        SearchParams.delete('s')
      }
      this.props.history.push({
        pathname: `/${window.tenancy}/${collection.name}`,
        search: SearchParams.toString(),
      })
      setTimeout(() => {
        this.loadFirstItems()
      }, 0)
    }
  }

  updateItem = async (id, nextValues) => {
    let { match: { params: { adapter } } } = this.props
    const result = await axios.post(`/api/collections/${adapter}/items/${id}`,
      { nextValues })
    const nextItems = this.state.items.map(i => {
      if (i._id === result.data.item._id) {
        return ({ ...result.data.item })
      }
      return i
    })
    this.setState({ items: [...nextItems] })
  }

  deleteRecords = async () => {
    console.log('deleteRecords')
    try {
      let { match: { params: { adapter } } } = this.props
      const {
        selectedItems,
        items,
      } = this.state
      const needToRemovesId = Object.keys(selectedItems).map(item => {
        return items[item]._id
      })
      console.log('needToRemovesId', needToRemovesId)
      const requestDeleteRecord = async id => {
        let { status } = await axios.delete(
          `/api/collections/${adapter}/items/` + id)
        return {
          status,
          id,
        }
      }
      const needUpdateState = await Promise.all(
        needToRemovesId.map(id => requestDeleteRecord(id)))
      this.setState({
        items: items.filter(item => {
          const deletedRecord = needUpdateState.find(
            needRemove => needRemove.id === item._id)
          if (deletedRecord) {
            if (deletedRecord.status === 200) {
              return false
            }
          }
          return true
        }),
        active: 0,
        selectedItems: { 0: null },
      })
    } catch (e) {
      console.error(e)
    }
  }

  render () {
    let {
      items,
      selectedItems,
      url,
      active,
      hasNextPage,
      isNextPageLoading,
      visibleImport,
      clearDuplicates,
    } = this.state
    let {
      match: { params: { adapter } },
      user,
    } = this.props

    const itemCount = hasNextPage ? items.length + 1 : items.length
    const loadMoreItems = () => {
    }
    const isItemLoaded = index => {
      const isLoaded = !hasNextPage || index < items.length
      if (!isLoaded) {
        if (!isNextPageLoading) {
          this.getNextItems()
        }
      }
      return isLoaded
    }

    let collection = this.getCollection()

    let {
      type,
      apps,
      appOptions,
    } = collection
    if (appOptions && type) {
      type.apps = type.apps.filter(app => {
        const option = appOptions.find(opt => opt.appName === app.name)
        if (option) {
          return !option.isDisabled
        } else {
          return true
        }
      })
    }
    let globalApps = []
    if (appOptions) {
      globalApps = appOptions.filter(ao => ao.globalTarget).map(ao => {
        if (!Object.isExtensible(ao)) { // not sure why, but
          // newly-created app options are not extensible and so cause a crash.
          // Ugly workaround:
          ao = { ...ao }
        }
        const globalVar = type.properties.find(v => v.name === ao.globalTarget)
        const globalType = globalVar && this.getGlobalType(globalVar.typeName)
        const currentApp = globalType &&
          globalType.apps.find(app => app.name === ao.appName)
        ao.label = ao.globalTarget + '  >  ' + (
          (currentApp && currentApp.label) || InferLabelFromAppName(ao.appName)
        )
        if (currentApp) {
          if (currentApp.label) {
            ao.appLabel = currentApp.label
          }
        }

        return ao
      })
    }
    if (items) {
      if (items) { // always most recent first
        items.sort((a, b) => (b.date > a.date) ? 1 : -1)
      }
    }
    let typeLabel = 'item'
    if (type) {
      if (type.name) {
        typeLabel = type.label || InferLabelFromName(type.name).toLowerCase()
      }
    }
    if (!type) {
      return null
    }
    let allApps = type.apps
    if (!allApps) {
      allApps = apps
    }
    if (!user.permissions.includes(adapter)) {
      return <Redirect to={{ pathname: `/${window.tenancy}/` }}/>
    }

    const Row = ({
      index,
      style,
    }) => {
      let selected = index in selectedItems

      return (
        <RowComponent item={items[index]} index={index} style={style}
                      active={selected ? index : active}
                      setActive={this.setActive.bind(this)}
                      loading={!isItemLoaded(index)}/>
      )
    }
    return (
      <div className={'row'}>
        <div className={'col-4 py-2'}>
          <div className={'row'}>
            <div className={'col-5'}>
              {allApps && <Dropdown overlay={(
                <Menu>
                  {allApps.map(app => <Menu.Item
                    key={app.name}><Link
                    to={{
                      pathname: `/${window.tenancy}/${adapter}/${app.name}`,
                    }} key={app.name}>{app.label ||
                    InferLabelFromAppName(app.name)}</Link></Menu.Item>)}
                  <Menu.Divider/>
                  <Menu.Item key={'import'} onClick={this.onOpenVisibleImport}
                             className={'p-0'}>
                    <label htmlFor={'importFile1'}
                           style={{ margin: '5px 12px' }}
                           className={'w-100 p-0'}><span>Import JSON or CSV</span></label>
                  </Menu.Item>
                  {window.knacklyAddons.dev && (
                    <Menu.Item key={'duplicates'}
                               onClick={this.onOpenClearDuplicates}
                               className={'p-0'}>
                      <label htmlFor={'clearDuplicates'}
                             style={{ margin: '5px 12px' }}
                             className={'w-100 p-0'}><span>Clear duplicates</span></label>
                    </Menu.Item>)}
                </Menu>)}
              >
                <button
                  className="btn btn-secondary btn-theme dropdown-toggle w-100 text-truncate"
                  type="button"
                  id="dropdownMenuButton">
                  {(collection.recordLabel ||
                    'CREATE RECORD').toUpperCase()} &nbsp;
                </button>
              </Dropdown>}
            </div>
            <div className={'col-7'}>
              <Search className={'input-group'}
                      onSubmit={this.onSearch}/>
            </div>
          </div>
        </div>
        {Object.keys(selectedItems).length < 2 &&
          (<div className={'col-6'}>
            <div className={'row'}>
              <Filters loadFirstItems={this.loadFirstItems}
                       catalogName={adapter}
                       apps={allApps}/>
            </div>
          </div>)}
        {globalApps.length > 0 && Object.keys(selectedItems).length < 2 &&
          (<div className={'col-2 py-2'}>
            <Dropdown overlay={(
              <Menu>
                {globalApps.map(app => <Menu.Item
                  key={app.globalTarget}><Link
                  to={{
                    pathname: `/${window.tenancy}/${adapter}/${app.globalTarget}/${app.appName}`,
                    search: ('appLabel' in app) && '?appLabel=' + app.appLabel,
                  }} key={app.globalTarget}>{app.label}</Link></Menu.Item>)}
              </Menu>)}
            >
              <button
                className="btn btn-secondary btn-theme dropdown-toggle w-100 text-truncate"
                type="button"
                id="dropdownMenuButton">
                {(collection.globalLabel ||
                  'Global Info').toUpperCase()} &nbsp;
              </button>
            </Dropdown>
          </div>)}
        {Object.keys(selectedItems).length > 1 &&
          (<div className={'col-2 offset-6 py-2'}>
            <ModalConfirm
              title={`Are you sure you want to delete selected records?`}
              submit={this.deleteRecords}>
              <button
                className="btn btn-secondary btn-theme w-100 text-truncate"
                type="button"
              ><Icon type="icon-trash"
                     style={{
                       fontSize: '1.25rem',
                       verticalAlign: '0.1em',
                     }}/> Delete records
              </button>
            </ModalConfirm>
          </div>)}
        <AdapterMain className={'col-12'}>
          <div className="row">
            <div className="col-4">
              <div style={{
                height: 'calc(100vh - 8rem)',
                overflow: 'hidden',
              }}>
                <div className="row mb-2 pr-3 px-2">
                  <div className="col-6 text-truncate">RECORDS</div>
                  <div className="col-4 text-truncate">LAST MODIFIED</div>
                  <div className="col-2 text-truncate">STATUS</div>
                </div>
                <AutoSizer>
                  {({
                    height,
                    width,
                  }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={itemCount}
                      loadMoreItems={loadMoreItems}
                    >{({
                      onItemsRendered,
                      ref,
                    }) => (
                      <List
                        style={{ overflowX: 'hidden' }}
                        height={height}
                        width={width}
                        onItemsRendered={onItemsRendered}
                        ref={ref}
                        itemCount={itemCount}
                        itemSize={55}>
                        {Row}
                      </List>)}</InfiniteLoader>
                  )
                  }
                </AutoSizer>
              </div>
            </div>
            {items[active] && <Detail item={items[active]}
                                      fileUrl={`${url}/download/${adapter}/${items[active]._id}`}
                                      checkInProgressOfItem={this.checkInProgressOfItem}
                                      updateItem={this.updateItem}
                                      disableActive={this.disableActive}
                                      remove={this.removeItem}
                                      allApps={allApps}
                                      typeLabel={typeLabel}
                                      adapter={adapter}/>}
          </div>
        </AdapterMain>
        <ImportItems visible={visibleImport}
                     loadFirstItems={this.loadFirstItems}
                     userId={user._id}
                     apps={allApps}
                     onClose={this.onClose}/>

        <ClearDuplicates visible={clearDuplicates}
                         loadFirstItems={this.loadFirstItems}
                         onClose={this.onCloseClearDuplicates}/>
      </div>
    )
  }
}

function mapStateToProps (state) {
  let {
    collections,
    user,
  } = state
  return {
    collections,
    user,
  }
}

export default connect(
  mapStateToProps,
)(Adapter)
