import ColorTable from '../utils/colorTable'
import { createGlobalStyle } from 'styled-components'

export let customTheme = (colors, size) => {
  const ct = new ColorTable(...colors)
  return ({
    colorTable: {
      ...ct,
    },
    size,
  })
}

export const GlobalStyle = createGlobalStyle`
  body {
    background: ${props => props.theme.colorTable.back} !important;
    color: ${props => props.theme.colorTable.textLight} !important;
    font-size: ${props => props.theme.size}px !important; ;
  }
  a {
    color: ${props => props.theme.colorTable.text};
  }
  h1 { font-size: 1.8rem !important; }
  h2 { font-size: 1.4rem !important; }
  h3 { font-size: 1.2rem !important; }
  h4 { font-size: 1.0rem !important; font-style: italic; }
  h5 { font-size: 1.0rem !important; }
  h6 { font-size: 1.0rem; font-weight: 600 !important; }
  h1, h2, h4, h5 {
    color:${props => props.theme.colorTable.textHeading} !important;
  }
  h3, h6 {
    color:${props => props.theme.colorTable.textHeadingDark} !important;
  }
  .navigation-bar a,
  .ant-dropdown a{
    color:${props => props.theme.colorTable.menuText} !important;
  }
  .activeMenuTop{
    background: ${props => props.theme.colorTable.menuSelection} !important;
    color:${props => props.theme.colorTable.menuSelectionText} !important;
  }
  .selectMap{
    background: ${props => props.theme.colorTable.mapSelection} !important;
  }
  .navigation-bar .activeMenuTop a,
  .navigation-bar a.activeMenuTop,
  .ant-dropdown a.activeMenuTop{
    color:${props => props.theme.colorTable.menuSelectionText} !important;
  }
  .navigation-bar a.activeMenuTop:focus,
  .navigation-bar a.activeMenuTop:hover {
    text-decoration: none !important;
    background: ${props => props.theme.colorTable.menuSelection} !important;
  }
  .navigation-bar a:focus,
  .navigation-bar a:hover,
  .navigation-bar #usermenu:hover {
    text-decoration: none !important;
    background: ${props => props.theme.colorTable.menuHover} !important;
    cursor: pointer !important;
  }
  /* label{
    color:${props => props.theme.colorTable.textLight}!important;
  } */
  .border-dark{
    border-color:${props => props.theme.colorTable.border}!important;
  }
  .dart-btn{
   background: ${props => props.theme.colorTable.widget} !important;
   border-color:${props => props.theme.colorTable.widget}!important;
   color: ${props => props.theme.colorTable.widgetText}!important;
   &:hover{
    background: ${props => props.theme.colorTable.widgetHover} !important;
    color: ${props => props.theme.colorTable.widgetText}!important;
   }
  }
  
  .btn-theme{
   background: ${props => props.theme.colorTable.widget} !important;
   border-color:${props => props.theme.colorTable.widget}!important;
   color: ${props => props.theme.colorTable.widgetText}!important;
   &:hover{
    background: ${props => props.theme.colorTable.widgetHover} !important;
    color: ${props => props.theme.colorTable.widgetText}!important;
   }
  }
  
  .dart-btn-outline{
   border-color:${props => props.theme.colorTable.widget}!important;
   color: ${props => props.theme.colorTable.textLight}!important;
   &:hover{
    background: ${props => props.theme.colorTable.backLight} !important;
    color: ${props => props.theme.colorTable.backLightText}!important;
   }
  }
  .circle-ant-btn{
   background: ${props => props.theme.colorTable.widget} !important;
   border-color:${props => props.theme.colorTable.widget}!important;
   color: ${props => props.theme.colorTable.widgetText}!important;
   &:hover{
    background: ${props => props.theme.colorTable.widgetHover} !important;
    color: ${props => props.theme.colorTable.widgetText}!important;
   }
  }
  .ant-dropdown-menu{
    background-color: ${props => props.theme.colorTable.menu}!important;
  }
  .ant-dropdown-menu-item{
   color: ${props => props.theme.colorTable.menuText}!important;
  }
  .ant-dropdown-menu-item:hover{
   background-color: ${props => props.theme.colorTable.menuHover}!important;
  }
  .ant-dropdown-menu-submenu-title{
    background-color: ${props => props.theme.colorTable.menu}!important;
  }
  .ant-dropdown-menu-submenu-title{
   color: ${props => props.theme.colorTable.menuText}!important;
  }
  .ant-dropdown-menu-submenu-title:hover{
   background-color: ${props => props.theme.colorTable.menuHover}!important;
  }
  .ant-dropdown-menu-submenu-arrow{
    color: ${props => props.theme.colorTable.menuText}!important;
  }
  .ant-dropdown-menu-submenu-arrow-icon{
    color: ${props => props.theme.colorTable.menuText}!important;
  }
  .form-control{
    border-color:${props => props.theme.colorTable.border};
    background-color: ${props => props.theme.colorTable.plain};
  }
  ::placeholder{
    color:${props => props.theme.colorTable.textPlaceholder}!important;
  }
  .signin{
    background-color: ${props => props.theme.colorTable.plain}!important;
    color: ${props => props.theme.colorTable.text}!important;
  }
  .nav-tabs {
    border-color: ${props => props.theme.colorTable.back}!important;
  }

  .nav-link{
     background-color: ${props => props.theme.colorTable.backLight}!important;
     border-color: ${props => props.theme.colorTable.backLight}!important;
     color: ${props => props.theme.colorTable.backText}!important;
    &:hover{
     background-color: ${props => props.theme.colorTable.backLightHover}!important;
     border-color: ${props => props.theme.colorTable.backLightHover}!important;
    }
    &.active{
      background-color: ${props => props.theme.colorTable.back}!important;
      border-color: ${props => props.theme.colorTable.back}!important;
    }
  }
  
  .knackly-plain{
    background-color: ${props => props.theme.colorTable.plain || '#FFFFFF'} !important;
    color:  ${props => props.theme.colorTable.textLight};
  }
  .knackly-plain-d{
    color:  ${props => props.theme.colorTable.textD} !important;
  }
  .knackly-back{
    background-color: ${props => props.theme.colorTable.back || '#EEEEEE'} !important;
    color:  ${props => props.theme.colorTable.backText} !important;
  }
  .knackly-back-d{
    color:  ${props => props.theme.colorTable.backTextD} !important;
  }
  .knackly-back-light{
    background-color: ${props => props.theme.colorTable.backLight || '#EEEEEE'} !important;
    color:  ${props => props.theme.colorTable.backLightText} !important;
  }
  .knackly-back-light-d{
    color:  ${props => props.theme.colorTable.backLightTextD} !important;
  }
  .knackly-back-dark{
    background-color: ${props => props.theme.colorTable.backDark || '#DDDDDD'} !important;
    color:  ${props => props.theme.colorTable.backDarkText} !important;
  }
  .knackly-back-dark-d{
    color:  ${props => props.theme.colorTable.backDarkTextD} !important;
  }
  .blockPermissions{
    border-color: ${props => props.theme.colorTable.back || '#EEEEEE'} !important;
  }
  .ant-btn:hover{
    color: ${props => props.theme.colorTable.widgetHover || '#EEEEEE'} !important;
  }
  .markdown{
   color: ${(props) => props.theme.colorTable.text || '#ffffff'}
  }
   .t-success{
    background: ${props => props.theme.colorTable.widget} !important;
    color:${props => props.theme.colorTable.widgetText} !important;
   }
   
   .right-nav-hollow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: background;
    transition-property: background;
  }
  .right-nav-hollow:hover, .right-nav-hollow:focus, .right-nav-hollow:active {
    background: ${props => props.theme.colorTable.menuSelection} !important;
    color:${props => props.theme.colorTable.menuSelectionText} !important;
  }   
  .filepond--panel-root {
    background-color: ${(props) => props.theme.colorTable.backLight || '#9B9B9B'} !important;
  }
  .filepond--drop-label {
    color: ${(props) => props.theme.colorTable.backLightText || '#ffffff'} !important;
  }
  .filepond--image-preview-overlay-success {
    color: ${props => props.theme.colorTable.widget} !important;
  }
  [data-filepond-item-state='processing-complete'] .filepond--item-panel {
    background-color: ${props => props.theme.colorTable.widget} !important;
  }
  .right-nav-hollow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: background;
    transition-property: background;
  }

  .right-nav-hollow:hover, .right-nav-hollow:focus, .right-nav-hollow:active {
    background: ${props => props.theme.colorTable.menuSelection} !important;
    color: ${props => props.theme.colorTable.menuSelectionText} !important;
  }

  .search-option {
    padding: 6px 12px;
    vertical-align: middle;
    overflow: hidden;
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: background;
    transition-property: background;
  }

  .search-option:hover, .right-nav-hollow:focus, .right-nav-hollow:active {
    background: ${props => props.theme.colorTable.menuHover} !important;
    color: ${props => props.theme.colorTable.menuText} !important;
  }

  .search-option-active {
    background: ${props => props.theme.colorTable.menuSelection} !important;
    color: ${props => props.theme.colorTable.menuSelectionText} !important;
  }

  .knackly-panel-active {
    background: ${props => props.theme.colorTable.menuSelection} !important;
    color: ${props => props.theme.colorTable.menuSelectionText} !important;
  }
  .search-option-label {
    display: flex;
    flex-direction:row;
    flex-wrap: nowrap;
    justify-content:flex-start;
  }
  .search-option-start {
    overflow:hidden;
    text-overflow:ellipsis;
    white-space:nowrap;
    flex-shrink: 1;
  }
  .search-option-end {
    white-space:nowrap;
    flex-basis:content;
    flex-grow: 0;
    flex-shrink: 0;

  }

`
