import React, { useState } from 'react'
import classNames from 'classnames'

import { Dropdown, Menu } from 'antd'
import ModalConfirm from '../../components/ModalConfirm'
import Icon from '../../components/Icon'
import {
  ReactComponent as UserSupport,
} from '../../components/Icon/user-support.svg'
import styled from 'styled-components'

const styleForIcon = {
  fontSize: 18,
  verticalAlign: 1,
  marginRight: 5,
}

const Item = styled.div`
  color: ${(props) => props.theme.colorTable.text};
  background: ${(props) => props.theme.colorTable.plain};
  border-color: ${(props) => props.theme.colorTable.backBorder};

  &:hover {
    background: ${(props) => props.theme.colorTable.backLightHover};
  }

  &.accent {
    color: ${(props) => props.theme.colorTable.listSelectionText};
    background: ${(props) => props.theme.colorTable.listSelection};
  }
`
const Card = ({
  name,
  date,
  user,
  mail = '',
  type,
  setActive,
  index,
  active,
  remove,
}) => {
  let [isHide, setIsHide] = useState(-1)
  return (
    <Item onClick={setActive(index)}
          onMouseOut={() => setIsHide(-1)}
          onMouseOver={() => setIsHide(index)}
          className={classNames(
            'col-12 border p-3  d-flex justify-content-between',
            { 'accent': index === active }, { 'border-top-0': index > 0 })}>
      <div>
        {mail && mail.includes('@knackly.io') ? (<i className="anticon"
                                                    style={{
                                                      fontSize: 18,
                                                      verticalAlign: 1,
                                                      marginRight: 5,
                                                    }}>
          <UserSupport style={{ width: 18, height: 18 }}/>
        </i>) : <Icon type={Icon.fromUser(user)} style={styleForIcon}/>}
        {name} {type === 'microsoft' && '(Microsoft)'}
      </div>


      <div className={classNames({ 'invisible': index !== isHide },
        { 'visible': index === isHide })}>
        <Dropdown overlay={
          <Menu>
            <Menu.Item>
              <ModalConfirm
                title={`Are you sure you want to permanently delete the user?`}
                submit={() => {
                  remove(index)
                  setActive(-1)
                }}>
                <span><Icon type="icon-trash"
                            style={{
                              fontSize: 14,
                              verticalAlign: '0.1em',
                            }}/><span> Delete user</span></span>
              </ModalConfirm>
            </Menu.Item>
          </Menu>}
        >
          <Icon type="icon-more" style={{
            fontSize: 18,
            verticalAlign: '0.1em',
          }}/>
        </Dropdown>
      </div>
    </Item>
  )
}

export default Card
